<template>
  <div :class="className" :style="{height:height,width:width}"></div>
</template>

<script>
// import echarts from 'echarts'
let echarts = require("echarts/lib/echarts");
require("echarts/theme/macarons"); // echarts theme
import { debounce } from "@/utils";

const animationDuration = 6000;

export default {
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "500px",
    },
    chartData: {
      type: Object,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val);
      },
    },
    //  barChartObj: function (newVal, oldVal) {
    //   // this.cData = newVal;  //newVal即是chartData
    //   newVal && this.columnarInit(); //newVal存在的话执行drawChar函数
    // },
  },
  mounted() {
    this.initChart();
    this.__resizeHanlder = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.__resizeHanlder);
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.__resizeHanlder);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    setOptions(obj) {
      console.log(obj);
      this.chart.setOption({
        title: {
          text: "设备详情",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
          textStyle: {
            // 提示框浮层的文本样式。
            color: "#fff",
          },
        },
        grid: {
          left: 10,
          right: 10,
          bottom: 20,
          top: 50,
          containLabel: true,
        },
        legend: {
          //   data: ["expected", "actual"],
        },
        xAxis: [
          {
            type: "category",
            data: obj.xaxis,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisTick: {
              show: false,
            },
          },
        ],
        series: obj.data,
      });
    },
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      this.setOptions(this.chartData);
    },
  },
};
</script>
