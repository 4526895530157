<template>
  <div id="home_page">
    <top-data></top-data>
    <adminDashboard></adminDashboard>
    <!-- <editorDashboard></editorDashboard> -->
  </div>
</template>

<script>
import adminDashboard from './compontent/admin'
// import editorDashboard from './compontent/editor'
import topData from './compontent/top.vue'
// import httpObj from '../../api/homePageApi/index.js'
export default {
  components: {
    topData,adminDashboard
  },
  props: {},
  data() {
    return {
    };
  },
  watch: {},
  computed: {},
  methods: {
  },
  created() {
  },
  mounted() {
  }
};
</script>
<style lang="scss" scoped>
#home_page  {
  .grid-content{
    background: #fff;
    min-width: 200px;
    height: 100px;
    box-shadow:2px 2px 5px #0000000d;
        border-color: rgba(0, 0, 0, 0.05);
        .icon_left{
          float: left;
          margin: 20px;
          font-size: 40px;
          color: #40c9c6;
          cursor: pointer;
          &:hover{
            color:#fff;
            background: #40c9c6;
          }
        }
        .detail_num{
          float: right;
          height: 100px;
          line-height: 100px;
          font-size: 40px;
          margin-right: 20px;
          color: #666;
        }
  }
}
</style>