<template>
  <div class="dashboard-editor-container">
    <el-row style="background:#fff;padding:16px 16px;margin-bottom:20px;">
      <el-select v-model="channelVal" placeholder="请选择渠道">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <div style="display: inline-block;margin-left:20px;">
        <el-date-picker v-model="timevalue" :popper-append-to-body="false" :editable="false" popper-class="timet_datepicker" align="center" size="small" type="datetimerange" value-format="timestamp" :clearable="false" range-separator="至" start-placeholder="开始日期" :picker-options="pickerOptions" end-placeholder="结束日期" @change="changeTimec()" :default-time="defaultTime"></el-date-picker>
      </div>
      <div style="display: inline-block;margin-left:20px;">
        <el-button type="primary" @click="queryFun()">查询</el-button>
      </div>
    </el-row>
    <el-row style="background:#fff;padding:16px 16px 0;margin-bottom:32px;">
      <barChart :chart-data="barData"></barChart>
    </el-row>
    <el-row style="background:#fff;padding:16px 16px;margin-bottom:20px;">
      <el-select v-model="channelValLine" placeholder="请选择渠道">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <div style="display: inline-block;margin-left:20px;">
        <el-date-picker v-model="timevalueLine" :popper-append-to-body="false" :editable="false" popper-class="timet_datepicker" align="center" size="small" type="datetimerange" value-format="timestamp" :clearable="false" range-separator="至" start-placeholder="开始日期" :picker-options="pickerOptions" end-placeholder="结束日期" @change="changeTimecLine()" :default-time="defaultTime"></el-date-picker>
      </div>
      <div style="display: inline-block;margin-left:20px;">
        <el-button type="primary" @click="queryFunLine()">查询</el-button>
      </div>
    </el-row>
    <el-row style="background:#fff;padding:16px 16px 0;margin-bottom:32px;">
      <line-charta :chart-data="orderNumList"></line-charta>
    </el-row>
    <el-row style="background:#fff;padding:16px 16px 0;margin-bottom:32px;">
      <line-charta :chart-data="cutNumList"></line-charta>
    </el-row>
    <el-row style="background:#fff;padding:16px 16px 0;margin-bottom:32px;">
      <line-charta :chart-data="flowList"></line-charta>
    </el-row>
  </div>
</template>

<script>
// import GithubCorner from '@/components/GithubCorner'
// import PanelGroup from "./components/PanelGroup";
// import LineChart from "./components/LineChart";
import LineCharta from "./components/LineCharta";
// import RaddarChart from "./components/RaddarChart";
// import PieChart from "./components/PieChart";
import BarChart from "./components/barCharta";
// import BarChart from "../../com/barChart.vue";
// import TransactionTable from "./components/TransactionTable";
// import TodoList from "./components/TodoList";
// import BoxCard from './components/BoxCard'
const animationDuration = 6000;
export default {
  name: "dashboard-admin",
  components: {
    // GithubCorner,
    // PanelGroup,
    // LineChart,
    LineCharta,
    // RaddarChart,
    // PieChart,
    BarChart,
    // TransactionTable,
    // TodoList,
    // BoxCard
  },
  data() {
    return {
      baseUrl: window.cfg.cutPhotoUrl,
      cutNumList: {
        title: "截帧数",
        xaxis: [],
        data: [],
      },
      barData: {
        data: [],
        xaxis: [],
      },
      flowList: {
        title: "流量(GB)",
        xaxis: [],
        data: [],
      },
      orderNumList: {
        title: "订单数",
        xaxis: [],
        data: [],
      },
      options: [
        {
          value: "tencent",
          label: "腾讯",
        },
      ],
      channelVal: "tencent",
      channelValLine: "tencent",
      defaultTime: ["00:00:00", "23:59:59"], //日期时间
      timevalue: [],
      timevalueLine: [],
      pickerOptions: {},
      stime: "",
      etime: "",
      dateTime: "", //选择的时间范围
      stimeLine: "",
      etimeLine: "",
      dateTimeLine: "", //选择的时间范围
    };
  },

  methods: {
    //获取渠道列表数据
    getChannel() {
      let { baseUrl } = this;
      $.ajax({
        url: `${baseUrl}/frameStatic/channelParam`,
        type: "GET",
        dataType: "JSON",
        contentType: "application/json",
        data: {},
        success: (data) => {
          // console.log(data.data);
          if (data.code == "00000") {
            this.options = data.dataList;
          }
        },
        error: (err) => {
          console.log(999999999);
        },
      });
    },
    //柱状图查询
    queryFun() {
      // console.log(this.timevalue);
      if (this.timevalue != []) {
        // this.getline(); //获取折线图数据
        this.getBar();
      }
    },
    //折线图查询
    queryFunLine() {
      // console.log(this.timevalueLine);
      if (this.timevalueLine != []) {
        this.getline(); //获取折线图数据
      }
    },
    //获取7天时间的默认值
    getDate() {
      const start = new Date(new Date().toLocaleDateString());
      // start.setTime(start.getTime());
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      var end = new Date(
        new Date(new Date().toLocaleDateString()).getTime() +
          24 * 60 * 60 * 1000 -
          1
      );
      this.windowetime = Math.round(end.getTime() / 1000);
      this.stime = start.getTime() / 1000;
      this.etime = Math.round(end.getTime()) / 1000;
      var arr = [start.getTime(), Math.round(end.getTime())];
      this.timevalue = arr;
    },
    //折线图获取当天时间的默认值
    getDateLine() {
      const start = new Date(new Date().toLocaleDateString());
      start.setTime(start.getTime());
      var end = new Date(
        new Date(new Date().toLocaleDateString()).getTime() +
          24 * 60 * 60 * 1000 -
          1
      );
      this.windowetime = Math.round(end.getTime() / 1000);
      this.stimeLine = start.getTime() / 1000;
      this.etimeLine = Math.round(end.getTime()) / 1000;
      var arr = [start.getTime(), Math.round(end.getTime())];
      this.timevalueLine = arr;
    },
    //选择时间
    changeTimec() {
      var startTime = "",
        endTime = "",
        newendTime = "";
      //   console.log(this.timevalue);
      if (this.timevalue == null) {
        this.stime = startTime;
        this.etime = newendTime;
      } else {
        startTime = this.timevalue[0] / 1000;
        endTime = this.timevalue[1];
        newendTime = Math.round(endTime / 1000);
        this.stime = startTime;
        this.etime = newendTime;
      }
    },
    //折线图选择时间
    changeTimecLine() {
      var startTime = "",
        endTime = "",
        newendTime = "";
      //   console.log(this.timevalue);
      if (this.timevalue == null) {
        this.stimeLine = startTime;
        this.etimeLine = newendTime;
      } else {
        startTime = this.timevalueLine[0] / 1000;
        endTime = this.timevalueLine[1];
        newendTime = Math.round(endTime / 1000);
        this.stimeLine = startTime;
        this.etimeLine = newendTime;
      }
      let dateTime = this.etimeLine - this.stimeLine; //时间范围
      this.dateTimeLine = dateTime * 1000;
      let millisecondsOfDay = 24 * 60 * 60 * 1000;
      if (this.dateTimeLine > millisecondsOfDay) {
        this.$message.warning("超过24小时，重新选择");
        this.timevalueLine = [];
      }
    },
    //获取折线图数据
    getline() {
      let { baseUrl } = this;
      // console.log(id);
      let obj = {
        channel: this.channelValLine,
        startTime: this.stimeLine * 1000,
        endTime: this.etimeLine * 1000,
      };
      $.ajax({
        url: `${baseUrl}/frame/cutStatic/getOrdercutFlow`,
        type: "POST",
        dataType: "JSON",
        contentType: "application/json",
        data: JSON.stringify(obj),
        success: (data) => {
          // console.log(data.data);
          if (data.code == "200") {
            //截帧数
            this.cutNumList.data = this.LineFun(data.data.cutNumList);
            this.cutNumList.xaxis = data.data.xaxis;
            //订单数
            this.orderNumList.data = this.LineFun(data.data.orderNumList);
            this.orderNumList.xaxis = data.data.xaxis;
            //流量
            let flowArr = data.data.flowList;
            flowArr.map((item, index) => {
              let itemArr = [];
              item.data.map((itema, indexa) => {
                //流量B转换成G
                let str = itema / 1024 / 1024 / 1024;
                itema = str.toFixed(4);
                itemArr.push(itema);
              });
              item.data = itemArr;
            });
            this.flowList.data = this.LineFun(data.data.flowList);
            this.flowList.xaxis = data.data.xaxis;
          }
        },
        error: (err) => {
          console.log(999999999);
        },
      });
    },
    //处理折线图数据
    LineFun(data) {
      let arr = [];
      data.map((item, index) => {
        // let colors = ["#3888fa",];
        let obj = {
          smooth: true,
          type: "line",
          itemStyle: {
            normal: {
              // color: colors[index],
              lineStyle: {
                // color: colors[index],
                width: 2,
              },
              areaStyle: {
                color: "#f3f8ff",
              },
            },
          },
          animationEasing: "cubicInOut",
        };
        arr.push({ ...obj, ...item });
      });
      console.log(arr);
      return arr;
    },
    BarFun(data) {
      let arr = [];
      data.map((item, index) => {
        let obj = {
          type: "bar",
          animationDuration,
        };
        arr.push({ ...obj, ...item });
      });
      console.log(arr);
      return arr;
    },
    //获取柱状图数据
    getBar() {
      let { baseUrl } = this;
      // console.log(id);
      let obj = {
        channels: this.channelVal,
        startTime: this.stime * 1000,
        endTime: this.etime * 1000,
      };
      $.ajax({
        url: `${baseUrl}/frameStatic/deviceStatic`,
        type: "GET",
        dataType: "JSON",
        contentType: "application/json",
        data: obj,
        success: (data) => {
          console.log(data.data);
          if (data.code == "00000") {
            //截帧数
            this.barData.data = this.BarFun(data.data.data);
            this.barData.xaxis = data.data.date;
          }
          console.log(this.barData);
        },
        error: (err) => {
          console.log(999999999);
        },
      });
    },
  },
  created() {
    this.getDate();
    this.getDateLine(); //折线图获取当天时间
    this.getline();
    this.getBar();
    this.getChannel();
  },
  mounted() {
    // this.getDate();
    // this.getDateLine(); //折线图获取当天时间
    // this.getBar();
    // this.getline(); //获取折线图数据
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.dashboard-editor-container {
  padding: 20px;
  background-color: rgb(240, 242, 245);
  .chart-wrapper {
    background: #fff;
    padding: 16px 16px 0;
    margin-bottom: 32px;
  }
}
</style>
